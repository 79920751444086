import type { Site } from "@datadog/browser-core/cjs/domain/configuration/intakeSites";

const env = {
  NODE_ENV: process.env.REACT_APP_NODE_ENV as string,
  BASE_URL: process.env.REACT_APP_BASE_URL as string,
  PLAYER_URL: process.env.REACT_APP_PLAYER_URL as string,
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL as string,
  CS_SOCKET_URL: process.env.REACT_APP_CS_SOCKET_URL as string,
  ADMIN_URL: process.env.REACT_APP_ADMIN_URL as string,
  VERSION: process.env.REACT_APP_APP_VERTION as string,
  SAVE_KEY: process.env.REACT_APP_SAVE_KEY as string,
  LIVE_LIST_SRC: process.env.REACT_APP_LIVE_LIST_SRC as string,
  LIVE_DETAIL_SRC: process.env.REACT_APP_LIVE_DETAIL_SRC as string,
  TALK_PLUS_APP_ID: process.env.REACT_APP_TALK_PLUS_APP_ID as string,
  FIREBASE: {
    API_KEY: process.env.REACT_APP_FIREBASE_API_KEY as string,
    AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string,
    PROJECT_ID: process.env.REACT_APP_FIREBASE_PROJECT_ID as string,
    STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string,
    MESSAGING_SENDER_ID: process.env
      .REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string,
    APP_ID: process.env.REACT_APP_FIREBASE_APP_ID as string,
    MEASUREMENT_ID: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID as string,
    VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY as string,
  },
  DATADOG: {
    APPLICATION_ID: process.env.REACT_APP_DATADOG_APPLICATION_ID as string,
    TOKEN: process.env.REACT_APP_DATADOG_TOKEN as string,
    SITE: process.env.REACT_APP_DATADOG_SITE as Site,
    SERVICE: process.env.REACT_APP_DATADOG_SERVICE as string,
  },
};

export default env;
