import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import {
  IActionLog,
  IActionParameter,
  TAction,
} from "interfaces/live.interface";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";
import { v4 as uuidV4 } from "uuid";
import { action_log, pending_tracking } from "api/live.request";
import { useRef } from "react";

const KEY = "log_id";

const defaultOptional = {
  color: null,
  isUp: null,
  optionId: null,
  price: null,
  productId: null,
  size: null,
  stock: null,
};

export default function useLog() {
  const params = useParams();
  const user = useRecoilValue(userState);

  const timer = useRef<NodeJS.Timer | null>(null);

  const getUUID = () => {
    let uuid = localStorage.getItem(KEY);

    if (!uuid) {
      uuid = uuidV4();
      localStorage.setItem(KEY, uuid);
    }

    return uuid;
  };

  const getPlatform = () => {
    let platform = "web";

    const userAgent = navigator.userAgent.toLowerCase();

    if (/(Mobile)/gi.test(userAgent)) {
      if (/android/gi.test(userAgent)) {
        platform = "android";
      } else if (/iPad|iPhone|iPod/gi.test(userAgent)) {
        platform = "ios";
      } else {
        platform = "mobile";
      }
    }

    return platform;
  };

  const pendingTracking = (
    method = "POST",
    url: string,
    requestBody: { [key: string]: any }
  ) => {
    const uuid = getUUID();
    const platform = getPlatform();

    timer.current = setTimeout(() => {
      pending_tracking({
        method,
        url,
        requestBody: JSON.stringify(requestBody),
        extra: JSON.stringify({
          uuid,
          platform,
        }),
      });

      timer.current = null;
    }, 3000);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
        timer.current = null;
      }
    };
  };

  const logging = (actionType: TAction, optionalData: IActionParameter) => {
    const broadcastId = params.id;

    if (!user || !broadcastId) return;

    const uuid = getUUID();
    const platform = getPlatform();

    const body: IActionLog[] = [
      {
        actionType,
        uuid,
        path: window.location.href,
        sellerId: user.userId,
        broadcastId: Number(broadcastId),
        platform,
        requesterCreatedAt: dayjs().format("YYYY-MM-DD HH:mm:ss.SSS"),
        ...defaultOptional,
        ...optionalData,
      },
    ];

    // action 전송
    action_log(broadcastId, body)
      .then(() => {
        //
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return { logging, getUUID, pendingTracking };
}
