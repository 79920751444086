import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import styled, { css } from "styled-components";
import { useLocation } from "react-router-dom";
import { hasOwnProperty } from "utils/lib";
import { fileUpload, getReportCategory, postReport } from "api/service.request";
import { IFile, IReportMeta } from "interfaces/service.interface";
import { userState } from "store/user";
import { Icon, Textarea, Upload, Button } from "components/shared";
import { maximumByte } from "utils/data";
import ContentLimit from "./ContentLimit";
import { useConfirmV2 } from "contexts/ConfirmV2Provider";

interface IProps extends IStyleProps {
  suspect?: string;
  meta: IReportMeta | null;
  onClose: () => void;
}

interface IStyleProps {
  toggle: boolean;
}

const ReportPopup = ({ toggle, suspect, meta, onClose }: IProps) => {
  const user = useRecoilValue(userState);
  const { confirm } = useConfirmV2();
  const location = useLocation();
  const [content, setContent] = useState<string>("");
  const [active, setActive] = useState<number>(1);
  const [images, setImages] = useState<(File | IFile)[]>([]);
  const contentLimitSize = 1000;

  const { data } = useQuery(["report-category", user], () => {
    if (!user) return null;
    return getReportCategory().then(({ data }) => {
      if (!data.data) return null;
      return data.data;
    });
  });

  const reportMutation = useMutation(postReport);
  const uploadMutation = useMutation(fileUpload);

  const uploadHandler = (newFiles: FileList | null) => {
    if (!newFiles) return;

    const nFiles = Array.from(newFiles);

    if (nFiles.filter(({ size }) => size > maximumByte).length !== 0) {
      confirm({
        invisibleCancel: true,
        description: "파일은 5MB 이하로 등록해주세요.",
      });
      return;
    }

    if (images.length + nFiles.length > 10) {
      // 제한 개수
      return;
    }

    setImages((prevState) => {
      return [...prevState, ...nFiles];
    });
  };

  const deleteHandler = (index: number) => {
    setImages((prevState) => {
      const newState = prevState.filter((_, i) => index !== i);
      return newState;
    });
  };

  const contentHandler = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = ev.target.value;
    if (value.length > contentLimitSize) return;

    setContent(value);
  };

  const closeHandler = () => {
    if (content !== "" || images.length > 0) {
      confirm({
        title: "알림",
        description: (
          <div>
            <div>작성중인 신고내용이 있습니다.</div>
            <div>닫으시겠습니까?</div>
          </div>
        ),
        onConfirm() {
          setActive(1);
          setImages([]);
          setContent("");
          onClose();
        },
      });

      return;
    }

    setActive(1);
    setImages([]);
    setContent("");
    onClose();
  };

  const submitHandler = async () => {
    if (!meta) {
      confirm({
        invisibleCancel: true,
        description: "신고정보가 부족합니다.",
      });
      return;
    }

    const match = location.pathname.match(/(post|chat)/);
    const type = match![0] === "post" ? "COMMENT" : "CHAT";

    try {
      let fileIds: number[] = [];

      if (images.length > 0) {
        const fd = new FormData();

        for (let i = 0; i < images.length; i++) {
          if (hasOwnProperty(images[i], "url")) {
            fileIds.push((images[i] as IFile).id);
          }

          if (images[i] instanceof File) {
            fd.append("files", images[i] as File);
          }
        }

        // file upload
        const { data } = await uploadMutation.mutateAsync(fd);

        if (data.data) {
          const newFileIds = data.data.map(({ fileId }) => fileId);
          fileIds = [...fileIds, ...newFileIds];
        }
      }

      reportMutation.mutate(
        {
          ...meta,
          type,
          reportCategoryId: active,
          content,
          fileIds,
        },
        {
          onSuccess() {
            confirm({
              invisibleCancel: true,
              description: "신고가 완료되었습니다.",
              onConfirm() {
                setContent("");
                setImages([]);
                setActive(1);
                onClose();
              },
            });
          },
          onError(err: any) {
            confirm({
              invisibleCancel: true,
              description: err.message,
            });
          },
        }
      );
    } catch (err: any) {
      confirm({
        invisibleCancel: true,
        description: err.message,
      });
    }
  };

  const loading = reportMutation.isLoading || uploadMutation.isLoading;

  return (
    <Wrapper toggle={toggle}>
      <Background className="bg-black opacity-80" onClick={closeHandler} />
      <Content toggle={toggle}>
        <div className="relative top-0 h-[46px] text-center flex justify-center items-center w-full bg-white border-b border-secondary-200 z-20 lg:w-[580px]">
          <h6>신고하기</h6>
          <button
            className="w-[46px] h-[46px] absolute right-0 top-0"
            onClick={onClose}
          >
            <Icon icon="xmark" fontSize={18} />
          </button>
        </div>

        <div className="box space-y-4 p-4">
          <div>
            <p className="text-secondary-900 space-x-2 text-sm">신고 유저명</p>
            <p className="font-medium">{suspect}</p>
          </div>
          <div>
            <div className="text-secondary-900 space-x-2">
              <span className="text-sm">사유선택</span>
              <span className="text-xs">중복 선택 불가</span>
            </div>
            <ul className="mt-4">
              {(data || []).map(({ name, id, description }) => {
                return (
                  <List key={id} isActive={active === id} active={active}>
                    <Item className="space-x-3">
                      <div className="inline-flex">
                        <input
                          type="radio"
                          name="type"
                          value={id}
                          checked={active === id}
                          onChange={() => setActive(id)}
                        />
                        <span className="radio" />
                      </div>
                      <div className="flex flex-col lg:flex-row">
                        <Label>{name}</Label>
                        <Description className="text-secondary-900">
                          {description}
                        </Description>
                      </div>
                    </Item>
                  </List>
                );
              })}
            </ul>
          </div>
          <div>
            <div className="text-secondary-900 space-x-2">
              <span className="text-sm">세부내용</span>
              <ContentLimit
                className="text-xs"
                contentLength={content.length}
                limitLength={contentLimitSize}
              />
            </div>
            <p className="mt-2">
              <Textarea
                value={content}
                placeholder="해당 신고 사유에 대한 세부내용을 작성해주세요."
                onChange={contentHandler}
              />
            </p>
            <div className="mt-2">
              <p className="text-secondary-900 space-x-2 text-sm">
                <span>첨부 이미지</span>
                <span>(선택 5MB 까지 등록가능)</span>
              </p>
              <div className="mt-2">
                <Upload.List files={images} onDelete={deleteHandler}>
                  <div className="flex items-center">
                    <Upload.Button onUpload={uploadHandler} />
                    {images.length < 10 && (
                      <div className="text-sm text-secondary-900 leading-4 ml-2">
                        <p>최대 10장 첨부가능</p>
                        <p>클릭해서 이미지 첨부</p>
                      </div>
                    )}
                  </div>
                </Upload.List>
              </div>
            </div>
          </div>
        </div>

        <div className="flex space-x-4 p-4 h-[74px] bg-white border-t border-secondary-200">
          <Button className="flex-1" ghost onClick={closeHandler}>
            취소
          </Button>
          <Button className="flex-1" loading={loading} onClick={submitHandler}>
            접수
          </Button>
        </div>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled.div<IStyleProps>`
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;

  ${(props) =>
    props.toggle &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const Content = styled.div<IStyleProps>`
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  transition: all 0.3s;
  top: 100%;
  left: 0%;
  display: flex;
  flex-direction: column;

  .box {
    height: calc(100% - 120px);
    overflow-y: auto;
  }

  ${(props) =>
    props.toggle &&
    css`
      top: 0px;

      @media (min-width: 1024px) {
        top: 24px;
      }
    `}

  @media (min-width: 1024px) {
    width: 580px;
    left: 50%;
    height: auto;
    max-height: calc(100% - 48px);
    transform: translateX(-50%);
    border-radius: 4px;
    overflow: hidden;
  }
`;

const List = styled.li<{ isActive: boolean; active?: number }>`
  position: relative;
  border: 1px solid #cccccc;
  margin-top: -1px;

  ${(props) =>
    props.isActive &&
    css`
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border: 1px solid #ef528f;
        top: -1px;
        left: -1px;
        z-index: 10;

        ${props.active === 1 &&
        css`
          border-radius: 12px 12px 0 0;
        `}

        ${props.active === 4 &&
        css`
          border-radius: 0 0 12px 12px;
        `}
      }
    `}

  &:first-child {
    border-radius: 12px 12px 0 0;
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
  }
`;

const Item = styled.label`
  cursor: pointer;
  padding: 14px;
  display: flex;
  align-items: center;

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked {
    & + .radio {
      border-color: #ef528f;
      &::after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  .radio {
    position: relative;
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 9px;
    border: 1px solid #d8d8d8;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background-color: #ef528f;
      transform: translate(-50%, -50%) scale(0);
    }
  }
`;

const Label = styled.span`
  font-weight: 500;
  font-size: 15px;
  margin-right: 10px;
`;

const Description = styled.p`
  font-size: 13px;
  word-break: keep-all;
`;

export default ReportPopup;
