import { IPagination } from "interfaces/service.interface";
import request, { Response, TodoResponse } from "./config";
import { INotice, INoticeDetail } from "interfaces/notice.interface";

// 공지목록조회
export const getNotices = (params: IPagination) =>
  request.get<TodoResponse<INotice[]>>(`/notices`, { params });

// 공지상세조회
export const getNotice = (noticeId: string | number) =>
  request.get<Response<INoticeDetail>>(`/notices/${noticeId}`);

// 팝업공지조회
export const getNoticePopup = () =>
  request.get<Response<INoticeDetail[]>>(`/notices/popup`);
