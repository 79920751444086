import { useCallback, useEffect, useRef } from "react";

const KEY = "scroll-history";

export default function useScrollHistory() {
  const ref = useRef<any>(null);

  const scrollHandler = (ev: Event) => {
    const element = ev.target as HTMLElement;
    sessionStorage.setItem(KEY, element.scrollTop.toString());
  };

  const clean = useCallback(() => {
    sessionStorage.setItem(KEY, "0");
  }, []);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        const scrollTop = sessionStorage.getItem(KEY);

        if (scrollTop !== undefined && !isNaN(Number(scrollTop))) {
          ref.current.scrollTo({
            top: Number(scrollTop),
          });
        }
      }, 0);

      ref.current.addEventListener("scroll", scrollHandler);
    }

    window.addEventListener("beforeunload", clean);
    return function cleanup() {
      window.removeEventListener("beforeunload", clean);
    };
  }, [clean]);

  return {
    ref,
  };
}
