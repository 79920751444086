import { LucideIcon } from "components/shared";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { classNameJoin } from "utils/lib";

interface IConfirmV2 {
  title?: string;
  description: React.ReactNode | string;
  options?: {
    textOfConfirm?: string;
    textOfCancel?: string;
  };
  // default false
  invisibleCancel?: boolean;
  // default true
  autoFocus?: boolean;
  onConfirm?: (dismiss: () => void) => Promise<boolean | void> | boolean | void;
  onCancel?: () => void;
}

interface IConfirmV2Props {
  show: (newState: IConfirmV2) => void;
}

const ConfirmV2Context = React.createContext<IConfirmV2Props>(null!);

// hooks
export const useConfirmV2 = () => {
  const context = useContext(ConfirmV2Context);

  const confirm = useCallback(
    (newState: IConfirmV2) => {
      context.show(newState);
    },
    [context]
  );

  return {
    confirm,
  };
};

// 초기 데이터
const initialState: IConfirmV2 = {
  title: undefined,
  description: "",
  invisibleCancel: false,
  autoFocus: true,
  onConfirm: undefined,
  onCancel: undefined,
};

const ConfirmV2Provider = ({ children }: React.PropsWithChildren<{}>) => {
  const timerRef = useRef<NodeJS.Timer | null>(null);
  const confirmRef = useRef<HTMLButtonElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [state, setState] = useState<IConfirmV2>(initialState);

  // confirm 닫기
  const dismiss = useCallback(() => {
    setIsOpen(false);
    timerRef.current = setTimeout(() => {
      setState({ ...initialState });
    }, 300);
  }, []);

  // confirm 열기
  const show = useCallback(
    (newState: IConfirmV2) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }

      const autoFocus =
        newState.autoFocus !== undefined ? newState.autoFocus : true;

      setState((prevState) => ({
        ...prevState,
        ...newState,
        autoFocus,
        async onConfirm() {
          if (newState.onConfirm) {
            setIsDisabled(true);
            const response = await newState.onConfirm(dismiss);
            setIsDisabled(false);

            if (typeof response === "boolean" && response === false) {
              return;
            }
          }

          dismiss();
        },
        onCancel() {
          if (newState.onCancel) newState.onCancel();
          dismiss();
        },
      }));

      setIsOpen(true);

      if (autoFocus) {
        setTimeout(() => {
          confirmRef.current?.focus();
        }, 100);
      }
    },
    [dismiss]
  );

  //
  const value = useMemo(() => {
    return {
      show,
    };
  }, [show]);

  return (
    <ConfirmV2Context.Provider value={value}>
      {children}

      {/* Section */}
      <div
        className={classNameJoin([
          "fixed left-0 right-0 bottom-0 top-0 z-[10000] transition-all",
          isOpen ? "visible opacity-100" : "invisible opacity-0",
        ])}
      >
        {/* Background */}
        <div
          className={classNameJoin([
            "absolute top-0 left-0 w-full h-full bg-black bg-opacity-60",
          ])}
        ></div>

        {/* Confirm */}
        <div
          className={classNameJoin([
            "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg min-w-80 overflow-hidden select-none transition-all bg-white",
            isOpen ? "translate-y-[-50%]" : "translate-y-[-40%]",
          ])}
        >
          {/* Body */}
          <div className="pt-7 pb-6 px-6">
            {/* Title */}
            {state.title ? (
              <div className="pb-3 text-center font-bold text-sm">
                {state.title}
              </div>
            ) : null}

            {/* Content */}
            <div className="text-center text-[15px]">{state.description}</div>
          </div>

          {/* Buttons */}
          <div className="flex">
            {/* Cancel */}
            {!state.invisibleCancel ? (
              <button
                className="flex-1 h-11 px-4 whitespace-nowrap rounded-bl-lg text-sm transition-all bg-secondary-900 hover:bg-secondary-850"
                onClick={state.onCancel}
              >
                {state.options?.textOfCancel || "취소"}
              </button>
            ) : null}

            {/* Confirm */}
            <button
              ref={confirmRef}
              className={classNameJoin([
                "flex-1 h-11 px-4 transition-all whitespace-nowrap text-sm bg-accect text-white hover:bg-primary-400 focus:bg-primary-400",
                !state.invisibleCancel ? "rounded-br-lg" : "rounded-b-lg",
                "disabled:bg-secondary-800 disabled:text-secondary-400",
              ])}
              disabled={isDisabled}
              onClick={() => state.onConfirm && state.onConfirm(dismiss)}
            >
              {isDisabled ? (
                <LucideIcon
                  className="mx-auto animate-spin"
                  name="Loader"
                  size={16}
                />
              ) : (
                <span>{state.options?.textOfConfirm || "확인"}</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </ConfirmV2Context.Provider>
  );
};

export default ConfirmV2Provider;
