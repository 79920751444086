import { useCallback, useEffect, useState } from "react";
import DOMpurify from "dompurify";
import { getNoticePopup } from "api/notice.request";
import { Checkbox, Icon } from "components/shared";
import { INoticeDetail } from "interfaces/notice.interface";
import { classNameJoin, openNewWindow } from "utils/lib";
import { useNavigate } from "react-router-dom";
import { ALLOWED_ATTR } from "../../constants/notice.constant";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";
import defaultPopupImage from "../../assets/default_popup_image.png";
import { useRecoilValue } from "recoil";
import { userState } from "store/user";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

interface CookieData {
  date: string;
  popupIds: number[];
}

function imageLoader(url: string) {
  return new Promise<string>((resolve) => {
    const image = new Image();

    image.onload = function () {
      resolve(url);
    };

    image.onerror = function () {
      resolve(defaultPopupImage);
    };

    image.src = url;
  });
}

/**
 * 오늘 하루만 보기 날짜 범위
 * ex) 일자 기준 입니다. 1일 20시 클릭 시, 2일 00시에 풀립니다.
 */

const GlobalNoticePopup = () => {
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies();

  const user = useRecoilValue(userState);

  const [isShow, setIsShow] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [todayIsDone, setTodayIsDone] = useState(false);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [popupList, setPopupList] = useState<INoticeDetail[]>([]);

  const getData = useCallback(async () => {
    if (!user) return;

    const { data } = await getNoticePopup();
    let result = data.data || [];

    if (user.isHiddenFromSearch) {
      const hiddenAccountNotice = createHiddenAccountNoticeData();
      result = [hiddenAccountNotice, ...result];
    }

    result = [...result.slice(0, 10)];
    if (!result || result.length === 0) return;

    for (let i = 0; i < result.length; i++) {
      const popup = result[i];
      if (popup.thumbnailFile) {
        popup.thumbnailFile.url = await imageLoader(popup.thumbnailFile.url);
      }
    }

    const cookieId = `${user.userId}`;
    const cookie = cookies[cookieId] as CookieData | null;

    let isExpired = true;

    if (cookie) {
      let isNewNotice = false;
      const passBy = dayjs(cookie.date).diff(new Date(), "day");

      for (let i = 0; i < result.length; i++) {
        const popup = result[i];

        if (!cookie.popupIds.includes(popup.id)) {
          isNewNotice = true;
          break;
        }
      }

      if (passBy < 0 || isNewNotice) {
        removeCookies(cookieId);
      } else {
        isExpired = false;
      }
    }

    if (!isExpired) return;

    setIsDisplay(true);
    setIsShow(true);
    setPopupList(result);
  }, [cookies, user]);

  // onError
  const setDefaultImage = (ev: React.SyntheticEvent<HTMLImageElement>) => {
    (ev.target as HTMLImageElement).src = defaultPopupImage;
  };

  // 닫기
  const closeHandler = () => {
    if (!user) return;

    setIsShow(false);

    if (todayIsDone) {
      const cookieId = `${user.userId}`;
      const date = dayjs().format("YYYY-MM-DD");
      const data = JSON.stringify({
        date,
        popupIds: popupList.map(({ id }) => id),
      });
      setCookies(cookieId, data);
    }

    setTimeout(() => {
      setIsDisplay(false);
    }, 500);
  };

  // link 가 있는 경우
  const moveTo = (popup: INoticeDetail) => {
    // 아무 동작도 없음
    if (popup.popupClickType === "NONE") return;

    if (popup.popupClickType === "LINK" && popup.popupLink) {
      openNewWindow(popup.popupLink);
    }

    if (popup.popupClickType === "DETAIL") {
      navigate(`/notice/${popup.id}`);
      closeHandler();
    }
  };

  useEffect(() => {
    if (!user) return;

    if (!window.location.pathname.includes("/live")) {
      getData();
    }
  }, [user]);

  if (!isDisplay) return null;

  return (
    <div
      className={classNameJoin([
        "fixed z-[1000] top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-70",
        isShow ? "animate-fade-in" : "animate-fade-out",
      ])}
    >
      <div
        className={classNameJoin([
          "flex flex-col rounded-xl overflow-hidden text-sm bg-white",
          "w-[400px] h-[600px] max-md:w-[300px] max-md:h-[460px]",
          isShow ? "animate-fade-in-up" : "animate-fade-out-down",
        ])}
      >
        {/* 본문 */}
        <div className="flex h-[calc(100%-40px)]">
          <Swiper
            loop
            className="noticeSwiper w-full [&>.swiper-wrapper]:h-[calc(100%-32px)]"
            navigation
            onSlideChange={(slide) => setSlideIndex(slide.realIndex)}
          >
            {popupList.map((popup) => {
              return (
                <SwiperSlide key={popup.id}>
                  <div
                    className={classNameJoin([
                      "h-full",
                      popup.popupClickType !== "NONE"
                        ? "cursor-pointer"
                        : "cursor-default",
                    ])}
                    onClick={() => moveTo(popup)}
                  >
                    {/* 이미지 or 텍스트 */}
                    {popup.thumbnailFile ? (
                      <img
                        src={popup.thumbnailFile.url}
                        className="w-full h-full object-cover"
                        alt={`공지 팝업 이미지`}
                        onError={setDefaultImage}
                      />
                    ) : (
                      <div className="flex flex-col h-full">
                        <div className="px-3 py-2 bg-black text-white">
                          {popup.title}
                        </div>
                        <div
                          className={classNameJoin([
                            "flex-1 overflow-auto notice-popup ql-container ql-snow",
                            popup.popupClickType !== "NONE"
                              ? "cursor-pointer"
                              : "cursor-default",
                          ])}
                        >
                          {/* font-size 는 global.css 에서 컨트롤 가능 */}
                          <div
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                              __html: DOMpurify.sanitize(popup.content, {
                                ALLOWED_ATTR,
                              }),
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              );
            })}

            <div className="flex justify-between items-center h-8 px-4">
              <SwiperButton type="prev" />
              <Bullet slideIndex={slideIndex} total={popupList.length} />
              <SwiperButton type="next" />
            </div>
          </Swiper>
        </div>

        {/* 버튼 */}
        <div className="flex items-center justify-between pl-3 pr-4 h-10 bg-neutral-200 text-neutral-500">
          <label>
            <Checkbox
              checked={todayIsDone}
              onChange={(ev) => setTodayIsDone(ev.target.checked)}
            >
              오늘은 그만보기
            </Checkbox>
          </label>
          <div>
            <button onClick={closeHandler}>닫기</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalNoticePopup;

//
function createHiddenAccountNoticeData(id = -1): INoticeDetail {
  return {
    id,
    type: "NOTICE",
    title: "휴면 계정 전환 안내",
    content: `<p>안녕하세요 셀러님,</p><p>최근 3개월 동안 라이브를 진행하지 않아 계정이 휴면 상태로 전환되었어요.</p><p><br/></p><p>휴면 계정이 되면 고객이 클릭메이트 검색창에서 셀러님을 찾을 수 없어요. 하지만 관리자 페이지의 모든 기능은 정상적으로 이용할 수 있으니 걱정하지 마세요.</p><p><br/></p><p>라이브를 진행하면 휴면 상태는 자동으로 해제돼요. 라이브를 예약하고 고객에게 셀러님을 다시 노출시켜 보세요!</p>`,
    popupClickType: "NONE",
    isPopup: true,
    popupLink: null,
    thumbnailFile: null,
    createdAt: "",
    updatedAt: "",
  };
}

interface BulletProps {
  total: number;
  slideIndex: number;
}
function Bullet({ total, slideIndex }: BulletProps) {
  return (
    <ul className="flex justify-center items-center flex-1 space-x-1.5">
      {Array(total)
        .fill(0)
        .map((_, i) => {
          return (
            <li
              key={i}
              className={classNameJoin([
                "w-2 h-2 rounded-full",
                i === slideIndex ? "bg-accect" : "bg-neutral-300",
              ])}
            ></li>
          );
        })}
    </ul>
  );
}

interface SwiperButtonProps {
  type: "next" | "prev";
}
function SwiperButton({ type }: SwiperButtonProps) {
  const swiper = useSwiper();

  const clickHandler = () => {
    if (!swiper) return;

    if (type === "next") {
      swiper.slideNext();
    } else if (type === "prev") {
      swiper.slidePrev();
    }
  };

  return (
    <button onClick={clickHandler}>
      <Icon
        icon={type === "next" ? `chevron-right` : `chevron-left`}
        fontSize={18}
      />
    </button>
  );
}
