import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { datadogRum } from "@datadog/browser-rum";
import { getUsersMe, postAuthRefresh } from "api/user.request";
import { setHeader } from "api/config";
import { useConfirmV2 } from "utils/helper";
import { accessTokenState, isLoginState, userState } from "store/user";
import {
  SignupPage,
  LoginPage,
  LogoutPage,
  CompletePage,
  FindEmailPage,
  FindPasswordPage,
  TossPaymentCheckingPage,
  LiveListPage,
  LiveProductPage,
  LiveDetailPage,
  LiveNowPage,
} from "./pages";
import ExternalBrowserModal from "pages/modal/ExternalBrowserModal";
import PWAInstallModal from "pages/modal/PWAInstallModal";
import LiveLayout from "layouts/LiveLayout";
import DetailProvider from "contexts/DetailProvider";
import GlobalNoticePopup from "components/notice/GlobalNoticePopup";
import env from "config/env";
import Home from "pages/Home";
import { STORAGE_KEY } from "config/const";

import "react-quill/dist/quill.snow.css";
import "swiper/css";

const Fallback = () => <div />;

function App() {
  const isDatadogReady = useRef<boolean>(false);
  const setIsLogin = useSetRecoilState(isLoginState);
  const [user, setUser] = useRecoilState(userState);
  const setAccessToken = useSetRecoilState(accessTokenState);
  const { confirm } = useConfirmV2();
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * 새로운 토큰 받아오기
   * @토큰정책
   * access_token은 발급 받은 후 24시간(정책에 따라 변동 가능)동안 유효합니다.
   * refresh token은 한달간 유효하며, refresh token 만료가 1주일 이상 남은 시점에서 사용자 토큰 갱신 요청을 하면 갱신된 access token만 반환됩니다.
   * refresh token 만료가 1주일 미만 남은 시점에서 사용자 토큰 갱신 요청을 하면 갱신된 access token과 갱신된 refresh token이 함께 반환됩니다.
   */
  const refresh = useCallback(async () => {
    const refreshToken = localStorage.getItem(STORAGE_KEY.refreshTokenKey);

    if (refreshToken) {
      const { data } = await postAuthRefresh({ refreshToken });

      if (data.data) {
        const { type, accessToken } = data.data.payload;
        setHeader(`${type} ${accessToken}`);
        setAccessToken(accessToken);
        setIsLogin(true);

        if (data.data.payload.refreshToken) {
          localStorage.setItem(
            STORAGE_KEY.refreshTokenKey,
            data.data.payload.refreshToken
          );
        }
      }
    }
  }, [setIsLogin, setAccessToken]);

  const me = useCallback(async () => {
    const {
      data: { data },
    } = await getUsersMe();

    if (data) {
      setUser(data);
    }
  }, [setUser]);

  const initialize = useCallback(async () => {
    try {
      await refresh();
      await me();
    } catch (err) {
      confirm({
        invisibleCancel: true,
        description: (
          <>
            <div>인증정보가 만료되었습니다.</div>
            <div>로그인 화면으로 이동합니다.</div>
          </>
        ),
        onConfirm() {
          navigate("/logout");
        },
      });
    }
  }, [refresh, me, confirm, navigate]);

  // DataDog 모니터링 시작
  // 로그인, 회원가입 같은 페이지는 제외됨
  const monitor = useCallback(() => {
    if (!user || !env.DATADOG.APPLICATION_ID || isDatadogReady.current) return;

    datadogRum.setGlobalContextProperty("seller", {
      id: user.userId,
      uuid: user.uuid,
    });

    datadogRum.init({
      applicationId: env.DATADOG.APPLICATION_ID,
      clientToken: env.DATADOG.TOKEN,
      site: env.DATADOG.SITE,
      service: env.DATADOG.SERVICE,
      env: env.NODE_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      version: env.VERSION,
    });

    isDatadogReady.current = true;
  }, [user]);

  useEffect(() => {
    const refreshToken = localStorage.getItem(STORAGE_KEY.refreshTokenKey);

    if (!refreshToken && !user && location.pathname === "/") {
      navigate("/login");
      return;
    }

    if (!user && location.pathname === "/") {
      navigate("/broadcast");
      return;
    }

    if (
      !user &&
      location.pathname !== "/login" &&
      location.pathname !== "/logout" &&
      location.pathname !== "/signup" &&
      location.pathname !== "/complete" &&
      location.pathname !== "/find-email" &&
      location.pathname !== "/find-password" &&
      location.pathname !== "/toss/payment-checking"
    ) {
      initialize();
    } else {
      monitor();
    }
  }, [user, initialize, location, navigate, monitor]);

  return (
    <div className="App">
      <ExternalBrowserModal />
      <PWAInstallModal />
      <Suspense fallback={<Fallback />}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/complete" element={<CompletePage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/find-email" element={<FindEmailPage />} />
          <Route path="/find-password" element={<FindPasswordPage />} />
          <Route
            path="/toss/payment-checking"
            element={<TossPaymentCheckingPage />}
          />

          <Route path="live" element={<LiveLayout />}>
            <Route path="list" element={<LiveListPage />} />
            <Route path="product/:id" element={<LiveProductPage />} />
            <Route
              path="detail/:id"
              element={
                <DetailProvider>
                  <LiveDetailPage />
                </DetailProvider>
              }
            />
            <Route path="now/:id" element={<LiveNowPage />} />
          </Route>

          <Route path="*" element={<Home />} />
        </Routes>
      </Suspense>

      <GlobalNoticePopup />
    </div>
  );
}

export default App;
