import React, { useContext, useState } from "react";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

import {
  Flag,
  ICheckedDetailOrder,
  IDetailTodo,
  TabType,
} from "interfaces/live.interface";
import { phoneHypen } from "utils/lib";
import { getDetailTodosForExcel } from "api/live.request";
import {
  ALL_TYPE,
  EXCEL_COLUMN,
  EXCEL_NAME,
  STATUS_MAP,
} from "../constants/live.constant";

interface Props {
  tab: TabType;
  checked: string[];
  checkedOrder: { [key: string]: ICheckedDetailOrder };
  flag: Flag;
  updateItem: IDetailTodo | null;
  isResetFlag: boolean;
  isOpenShippingInfo: boolean;

  setTab: (type: TabType) => void;
  setChecked: (orders: ICheckedDetailOrder[], checked?: boolean) => void;

  onAllChecked: (orders: ICheckedDetailOrder[], checked?: boolean) => void;
  onFlag: (reason: string, option?: any) => void;
  onExportExcel: (broadcastId?: string) => Promise<boolean>;
  onOpenShpiingInfo: (orders: IDetailTodo) => void;
  onCloseShippingInfo: () => void;
}

const DetailContext = React.createContext<Props>(null!);

export const useDetail = () => {
  const context = useContext(DetailContext);
  return context;
};

export default function DetailProvider({
  children,
}: React.PropsWithChildren<{}>) {
  // option
  const [isResetFlag, setIsResetFlag] = useState<boolean>(false);
  const [flag, setFlag] = useState<Flag>({
    status: false,
    reason: null,
    option: null,
  });
  const [tab, setTab] = useState<TabType>("ORDER");
  const [checked, setChecked] = useState<{
    [key: string]: ICheckedDetailOrder;
  }>({});

  const [isOpenShippingInfo, setIsOpenShippingInfo] = useState(false);
  const [updateItem, setUpdateItem] = useState<IDetailTodo | null>(null);

  // Set Middleware
  const _setTab = (tab: TabType) => {
    setTab(tab);
    setChecked({});
  };

  const _setChecked = (orders: ICheckedDetailOrder[], checked?: boolean) => {
    setChecked((prevState) => {
      const copy = { ...prevState };

      if (checked === true) {
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          copy[order.id] = order;
        }
      } else if (checked === false) {
        for (let i = 0; i < orders.length; i++) {
          const order = orders[i];
          delete copy[order.id];
        }
      }

      return copy;
    });
  };

  // Handler Event

  //
  const onAllChecked = (orders: ICheckedDetailOrder[], checked?: boolean) => {
    if (checked) {
      const _orders: { [key: string]: ICheckedDetailOrder } = {};

      for (let i = 0; i < orders.length; i++) {
        _orders[orders[i].id.toString()] = orders[i];
      }

      setChecked(_orders);
    } else {
      setChecked({});
    }
  };

  //
  const onFlag = (reason: string, option: any = null) => {
    setFlag((prevState) => {
      return {
        status: !prevState.status,
        reason,
        option,
      };
    });

    setTimeout(() => {
      setFlag({ status: false, reason: null, option: null });
    }, 1000);
  };

  /**
   *
   */
  const onExportExcel = async (broadcastId?: string) => {
    if (!broadcastId) return false;

    const filename = EXCEL_NAME[tab];
    const status = tab === "ALL" ? ALL_TYPE : tab;
    const response = await getDetailTodosForExcel(broadcastId, status);
    let data = response.data.data;
    console.log("excel", data);

    if (!data || data.length === 0) return false;

    let headers = Object.values(EXCEL_COLUMN);
    let columns = Object.keys(EXCEL_COLUMN);

    if (tab === "DELIVERY") {
      headers.push("배송일시");
      columns.push("updatedAt");
    } else if (tab === "ALL") {
      headers = ["주문일시", "주문상태", "상태변경일", ...headers];
      columns = ["createdAt", "status", "updatedAt", ...columns];
    }

    const rows: (string | number)[][] = [headers];

    for (let i = 0; i < data.length; i++) {
      const d = data[i];
      const row: (string | number)[] = [];

      for (let j = 0; j < columns.length; j++) {
        const column = columns[j];
        const value = d[column as keyof typeof d];
        let _value: string | number =
          value !== null && value !== undefined ? String(value) : "-";

        if (column === "phone" || column === "shippingPhone") {
          // 핸드폰번호 처리
          _value = phoneHypen(_value);
        } else if (column === "createdAt" || column === "updatedAt") {
          // 날짜 처리
          _value = dayjs(_value).format("YYYY-MM-DD HH:mm:ss");
        } else if (column === "status") {
          // 상태 처리
          _value = STATUS_MAP[_value];
        } else if (
          column === "price" ||
          column === "count" ||
          column === "totalPrice" ||
          column === "totalPayment"
        ) {
          // 숫자 처리
          _value = Number(_value);
        }

        row.push(_value);
      }
      rows.push(row);
    }

    const sheet = XLSX.utils.aoa_to_sheet(rows);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, sheet, "Sheet1");
    XLSX.writeFile(book, filename);
    return true;
  };

  //
  const onOpenShpiingInfo = (item: IDetailTodo) => {
    setIsOpenShippingInfo(true);
    setUpdateItem(item);
  };

  const onCloseShippingInfo = () => {
    setIsOpenShippingInfo(false);

    setTimeout(() => {
      setUpdateItem(null);
    }, 500);
  };

  const value: Props = {
    // state
    tab,
    checked: Object.keys(checked),
    checkedOrder: checked,
    flag,
    updateItem,
    isResetFlag,
    isOpenShippingInfo,

    // set
    setTab: _setTab,
    setChecked: _setChecked,

    // handler
    onAllChecked,
    onFlag,
    onExportExcel,
    onOpenShpiingInfo,
    onCloseShippingInfo,
  };

  return (
    <DetailContext.Provider value={value}>{children}</DetailContext.Provider>
  );
}
