import {
  IActionLog,
  IDetailForExcel,
  IDetailOrderCount,
  IDetailShippingInfo,
  IDetailStatistics,
  IDetailTodo,
  ILiveItem,
  ILiveParams,
  IOption,
  IOrder,
  IPendingBody,
  IProductExcelData,
  IProductOption,
  IProductOptionBody,
  OrderInfo,
} from "interfaces/live.interface";
import request, { Response, TodoResponse } from "./config";
import {
  ILiveProduct,
  IProductReturn,
  ShippingType,
} from "interfaces/broadcast.interface";
import socket from "./socket";
import { IPagination } from "interfaces/service.interface";

//
export const getLiveBroadcast = (params: ILiveParams) =>
  request.get<TodoResponse<ILiveItem[]>>("/broadcasts", {
    params,
  });

//
export const getLiveBroadcastProduct = (broadcastId: string) =>
  request.get<TodoResponse<ILiveProduct[]>>(
    `/broadcasts/${broadcastId}/products`
  );

//
export const getOptions = (type: "SIZE" | "COLOR") =>
  request.get<Response<IOption[]>>(`/products/options`, {
    params: {
      type,
    },
  });

//
export const getProductsOptions = (
  broadcastId: string | number,
  productId: string | number
) =>
  request.get<Response<IProductOption[]>>(
    `/broadcasts/${broadcastId}/products/${productId}/options`
  );

//
export const getDetailOrderCount = (broadcastId: string) =>
  request.get<Response<IDetailOrderCount>>(
    `/broadcasts/${broadcastId}/order-count`
  );

//
export const getNowOrder = (
  broadcastId: string | number,
  productId: string | number,
  params?: {
    page: number;
    size: number;
  }
) =>
  request.get<TodoResponse<IOrder[], OrderInfo>>(
    `broadcasts/${broadcastId}/products/${productId}/orders`,
    {
      params: {
        ...params,
        orderStatus: "REQUEST, COMPLETE, CANCEL_REQUEST",
      },
    }
  );

//
export const getDetailTodosForExcel = (
  broadcastId: string,
  status: string,
  searchWord?: string
) =>
  request.get<Response<IDetailForExcel[]>>(`broadcasts/${broadcastId}/orders`, {
    params: {
      status,
      searchWord: searchWord ? encodeURIComponent(searchWord) : null,
    },
  });

//
export const getDetailTodos = (
  broadcastId: string,
  status: string,
  searchWord: string | null = null,
  pagination: IPagination
) =>
  request.get<TodoResponse<IDetailTodo[]>>(
    `/v2/broadcasts/${broadcastId}/orders`,
    {
      params: {
        status,
        searchWord,
        ...pagination,
      },
    }
  );

//
export const getOrderStatistics = (broadcastId: string) =>
  request.get<Response<IDetailStatistics[]>>(
    `/broadcasts/${broadcastId}/products/order-statistics`
  );

//
export const postOptions = (body: { name: string; type: "COLOR" | "SIZE" }) =>
  request.post(`/products/options`, body);

//
export const postUnpaidUserNotifications = (broadcastId: string) =>
  request.post<Response>(
    `/broadcasts/${broadcastId}/unpaid-users/notifications`
  );

//
export const putShippingInfo = (
  broadcastId: string,
  body: {
    shippingCharge: number | null;
    shippingPurchaseAmount: number | null;
    shippingType: ShippingType;
  }
) => request.put(`broadcasts/${broadcastId}/shipping-info`, body);

//
export const postResetProduct = (broadcastId: string) =>
  request.post(`/broadcasts/${broadcastId}/products/reset`, {
    products: [],
  });

//
export const patchOrderStatusBulk = (body: {
  orderIds: (string | number)[];
  status: number;
}) => request.patch(`/orders`, body);

// 주문코드 기준 주문상태 변경
export const patchOrdersCodes = (body: { orderCodes: string[] }) =>
  request.patch<Response>(`/orders/codes`, body);


//
export const deleteOptions = (optionId: string | number) =>
  request.delete(`/products/options/${optionId}`);

////////////////
/////// SOCKET
////////////////

/** V2 Start (라이브일때 사용되는 API) */

// 라이브 방송 상품 추가
export const postProductsV2 = (
  broadcastId: string,
  body: { name: string; price: string; stock: string }
) =>
  socket.post<Response<IProductReturn>>(
    `/broadcasts/${broadcastId}/live/products`,
    body
  );

// 라이브 방송 상품 변경
export const patchProductPropertyV2 = (
  broadcastId: string | number,
  productId: string | number,
  body: { [key: string]: number | string | boolean | null }
) =>
  socket.patch(`/broadcasts/${broadcastId}/live/products/${productId}`, body);

// 라이브 방송 상품 주문 취소
export const cancelProductOrderV2 = (
  broadcastId: string | number,
  productId: string | number,
  body: {
    guestId: string | number;
    optionId: string | number | null;
    price: string | number;
  }
) =>
  socket.post(
    `/broadcasts/${broadcastId}/live/products/${productId}/orders/cancel`,
    body
  );

// 라이브 중 주문 상태 변경
export const patchOrderStatusV2 = (
  orderId: string | number,
  body: {
    status?: number; // 상태
    count?: number; // 주문수량
    option?: string; // 주문옵션 - 사이즈, 색상
    from?: string;
  }
) => socket.patch(`/orders/${orderId}/live`, body);

//
export const postProductsOptionsV2 = (
  broadcastId: string | number,
  productId: string | number,
  body: IProductOptionBody
) =>
  socket.post(`/broadcasts/${broadcastId}/live/products/${productId}/options`, {
    color: body.color,
    number: body.number,
    size: body.size,
    stock: body.stock,
  });

//
export const patchProductsOptionsV2 = (
  broadcastId: string | number,
  productId: string | number,
  optionId: string | number,
  body: IProductOptionBody
) =>
  socket.patch(
    `/broadcasts/${broadcastId}/live/products/${productId}/options/${optionId}`,
    {
      color: body.color,
      number: body.number,
      size: body.size,
      stock: body.stock,
    }
  );

//
export const deleteProductsOptionsV2 = (
  broadcastId: string | number,
  productId: string | number,
  optionId: string | number
) =>
  socket.delete(
    `/broadcasts/${broadcastId}/live/products/${productId}/options/${optionId}`
  );

/** V2 End */

//
export const postProducts = (
  broadcastId: string,
  body: { name: string; price: string; stock: string }
) =>
  socket.post<Response<IProductReturn>>(
    `/broadcasts/${broadcastId}/products`,
    body
  );

//
export const postExcelProduct = (
  broadcastId: string,
  body: IProductExcelData
) => socket.post(`/v2/broadcasts/${broadcastId}/products`, body);

//
export const postProductsOptions = (
  broadcastId: string | number,
  productId: string | number,
  body: IProductOptionBody
) =>
  socket.post(`/broadcasts/${broadcastId}/products/${productId}/options`, {
    color: body.color,
    number: body.number,
    size: body.size,
    stock: body.stock,
  });

//
export const postShippingInfo = (
  broadcastId: string,
  body: IDetailShippingInfo
) => socket.post(`/broadcasts/${broadcastId}/shipping-info`, body);

//
export const postTimeIncrease = (broadcastId: string | number) =>
  socket.post(`/broadcasts/${broadcastId}/times`);

//
export const patchProductsOptions = (
  broadcastId: string | number,
  productId: string | number,
  optionId: string | number,
  body: IProductOptionBody
) =>
  socket.patch(
    `/broadcasts/${broadcastId}/products/${productId}/options/${optionId}`,
    {
      color: body.color,
      number: body.number,
      size: body.size,
      stock: body.stock,
    }
  );

//
export const patchProductProperty = (
  broadcastId: string | number,
  productId: string | number,
  body: { [key: string]: number | string | boolean | null }
) => socket.patch(`/broadcasts/${broadcastId}/products/${productId}`, body);

//
export const patchOrderStatus = (orderId: string | number, status: number) =>
  socket.patch(`/orders/${orderId}`, {
    status,
  });

//
export const patchOrderOptions = (
  productId: string | number,
  body: { [key: string]: string | number | null | undefined }
) => socket.patch(`/orders/${productId}`, body);

//
export const putMaxPeople = (
  broadcastId: string | number,
  body: { maxCount: number }
) => socket.put(`/broadcasts/${broadcastId}/max-people`, body);

//
export const cancelProductOrder = (
  broadcastId: string,
  orderId: string | number
) => socket.delete(`/broadcasts/${broadcastId}/orders/${orderId}`);

//
export const deleteAllProductsOptions = (
  broadcastId: string | number,
  productId: string | number
) =>
  socket.delete(`/broadcasts/${broadcastId}/products/${productId}/options/all`);

//
export const deleteProductsOptions = (
  broadcastId: string | number,
  productId: string | number,
  optionId: string | number
) =>
  socket.delete(
    `/broadcasts/${broadcastId}/products/${productId}/options/${optionId}`
  );

///////////
/// LOG API
///////////
export const action_log = (broadcastId: string | number, body: IActionLog[]) =>
  request.post(`/broadcasts/${broadcastId}/product-actions`, body);

export const pending_tracking = (body: IPendingBody) =>
  request.post(`/manage/pending-api`, body);
