import { NavLink, Outlet, useNavigate } from "react-router-dom";
import SymbolIcon from "../assets/symbol_icon.png";
import SymbolText from "../assets/symbol_text.png";
import { useConfirmV2 } from "utils/helper";

export default function LiveLayout() {
  const navigate = useNavigate();
  const { confirm } = useConfirmV2();

  const logoutHandler = () => {
    confirm({
      title: "로그아웃",
      description: "로그아웃 하시겠습니까?",
      onConfirm() {
        navigate("/logout");
      },
    });
  };

  return (
    <div className="w-full h-full overflow-x-auto">
      {/* Header */}
      <header className="flex items-center justify-between w-full h-[50px] px-2 border-b border-secondary-900">
        <NavLink to="/live/list" className="flex items-center min-w-36 ml-2">
          <img src={SymbolIcon} width={36} alt="symbol icon" />
          <div className="ml-1 -mt-0.5">
            <img src={SymbolText} width={86} alt="symbol text" />
            <div className="text-[11px] -mt-1.5">셀러 관리자</div>
          </div>
        </NavLink>

        <div className="pr-1">
          <button className="text-sm" onClick={logoutHandler}>
            로그아웃
          </button>
        </div>
      </header>

      {/* Body */}
      <div className="w-full h-[calc(100%-50px)] overflow-hidden bg-[#f9f9f9]">
        <Outlet />
      </div>
    </div>
  );
}
