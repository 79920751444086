import { TNoticeType } from "interfaces/notice.interface";

export const CONFIG_OF_TYPE: {
  [key in TNoticeType]: {
    style: string;
    title: string;
  };
} = {
  EVENT: {
    style: "text-violet-800 border-violet-200",
    title: "이벤트",
  },
  NOTICE: {
    style: "text-neutral-500 border-neutral-300",
    title: "공지",
  },
  UPDATE: {
    style: "text-indigo-500 border-indigo-100",
    title: "업데이트",
  },
};

export const ALLOWED_ATTR = [
  "target",
  "href",
  "src",
  "alt",
  "class",
  "id",
  "style",
  "rel",
  "width",
  "height",
];
