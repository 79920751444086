import env from "./env";
import { faClipboard, faEnvelope } from "@fortawesome/free-regular-svg-icons";

const navs = [
  {
    title: "콘텐츠",
    items: [
      {
        path: "/broadcast",
        basename: "broadcast",
        icon: ["far", "calendar"],
        name: "라이브 예약",
      },
      {
        path: "/point",
        basename: "point",
        icon: ["far", "gem"],
        name: "포인트 관리",
      },
      {
        path: "/chat",
        basename: "chat",
        icon: ["far", "comment-dots"],
        name: "문의톡 목록",
      },
      {
        path: env.LIVE_LIST_SRC,
        basename: "order details",
        icon: faClipboard,
        name: "주문관리",
      },
    ],
  },
  {
    title: "사용자",
    items: [
      {
        path: "/profile",
        basename: "profile",
        icon: "cog",
        name: "내 설정",
      },
      {
        path: "/notice",
        basename: "notice",
        icon: "pen-to-square",
        name: "공지사항",
      },
      {
        path: "https://tally.so/r/mOd06g",
        basename: "feedback",
        icon: faEnvelope,
        name: "의견 보내기",
      },
      {
        path: "/logout",
        basename: "logout",
        icon: "arrow-right-from-bracket",
        name: "로그아웃",
      },
    ],
  },
];

export default navs;
