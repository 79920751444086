import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import navs from "config/navs";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Icon } from "components/shared";
import { classNameJoin } from "utils/lib";
import { useCategoryUnreadCount } from "contexts/CategoryUnreadCountProvider";
import env from "config/env";

interface IProps extends IStyleProps {
  onToggle: () => void;
}

interface IStyleProps {
  toggle: boolean;
}

const GlobalNavigationBar = ({ toggle, onToggle }: IProps) => {
  const { pathname } = useLocation();
  const domain = pathname.split("/")[1];
  const { categoryUnreadCount } = useCategoryUnreadCount();

  const handleToggleWithStopPropagation = (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    e.stopPropagation();
    onToggle();
  };

  return (
    <>
      <Background toggle={toggle} onClick={onToggle} />
      <GNB toggle={toggle}>
        <div className="h-full w-[230px] py-2 space-y-4 bg-white border-r border-secondary-900">
          {navs.map((nav, i) => (
            <div className="relative px-3" key={i}>
              <div className="pt-1 pb-3 text-xs text-secondary-400">
                {nav.title}
              </div>

              <ul>
                {nav.items.map((item) => {
                  const { path, name, icon } = item;

                  if (name === "주문관리") {
                    return (
                      <li key={path} onClick={onToggle}>
                        <NavLink
                          to="/live/list"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="flex items-center h-9 px-2 rounded-md text-secondary-500">
                            <Icon
                              className="w-6 text-center"
                              icon={icon as IconProp}
                            />
                            <span className="ml-3 text-sm">{name}</span>
                          </div>
                        </NavLink>
                      </li>
                    );
                  }

                  if (name === "의견 보내기") {
                    return (
                      <li key={path} onClick={onToggle}>
                        <NavLink to={path} target="_blank" rel="noreferrer">
                          <div className="flex items-center h-9 px-2 rounded-md text-secondary-500">
                            <Icon
                              className="w-6 text-center"
                              icon={icon as IconProp}
                            />
                            <span className="ml-3 text-sm">{name}</span>
                          </div>
                        </NavLink>
                      </li>
                    );
                  }

                  if (path) {
                    return (
                      <li
                        key={path}
                        onClick={
                          name === "문의톡 목록"
                            ? handleToggleWithStopPropagation
                            : onToggle
                        }
                      >
                        <NavLink
                          className={({ isActive }) => {
                            return classNameJoin([
                              "flex items-center h-9 px-2 rounded-md",
                              isActive || path.indexOf(domain) !== -1
                                ? " text-accect bg-primary-950 font-medium"
                                : "text-secondary-500",
                            ]);
                          }}
                          to={path}
                        >
                          <Icon
                            className="w-6 text-center"
                            icon={icon as IconProp}
                          />
                          <span className="ml-3 text-sm">{name}</span>

                          {name === "문의톡 목록" &&
                            categoryUnreadCount.total > 0 && (
                              <span className="ml-auto w-[35px] h-[20px] bg-accect text-white flex items-center mt-0.5 justify-center pr-[1px] pb-[1px] rounded-xl text-sm">
                                {categoryUnreadCount.total > 99
                                  ? "99+"
                                  : categoryUnreadCount.total}
                              </span>
                            )}
                        </NavLink>
                      </li>
                    );
                  }

                  return null;
                })}
              </ul>
            </div>
          ))}

          {env.NODE_ENV === "development" ? (
            <div className="relative px-3">
              <div className="pt-1 pb-3 text-xs text-secondary-400">실험실</div>

              <ul>
                {/* toss */}
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return classNameJoin([
                        "flex items-center h-9 px-2 rounded-md",
                        isActive || "/toss".indexOf(domain) !== -1
                          ? " text-accect bg-primary-950 font-medium"
                          : "text-secondary-500",
                      ]);
                    }}
                    to={`/toss`}
                  >
                    <Icon
                      className="w-6 text-center"
                      icon={["far", "credit-card"]}
                    />
                    <span className="ml-3 text-sm">Toss 결제</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          ) : null}
        </div>
      </GNB>
    </>
  );
};

const GNB = styled.nav<IStyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  z-index: 999;

  transform: translateX(${(props) => (props.toggle ? "0px" : "-230px")});

  @media (min-width: 1024px) {
    transform: translateX(0px);
  }
`;

const Background = styled.div<IStyleProps>`
  cursor: pointer;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 998;
  transition: all 0.3s;
  visibility: ${(props) => (props.toggle ? "visible" : "hidden")};
  opacity: ${(props) => (props.toggle ? 1 : 0)};

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: 1024px) {
    display: none;
  }
`;

export default GlobalNavigationBar;
